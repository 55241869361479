<template>
  <div
    class="card has-background-grey-lighter has-border-radius shadow-none mb-1 p-3"
  >
    <div class="columns">
      <div class="column text-left flex items-center">
        <b-icon
          :icon="
            tipo_garantia.parent_tipos_garantia_id == '3'
              ? 'dollar-sign'
              : 'university'
          "
          size="is-large"
          class="has-background-very-grey-light has-text-grey-darker rounded-full mx-3 px-3"
        />
        <div class="flex justify-center flex-col">
          <p class="has-text-grey-light">{{ arrendamiento ? 'Descripcion' : 'Tipo' }}</p>
          <b-tooltip
            :label="arrendamiento ? garantia.descripcion : tipo_garantia.nombre"
            type="is-dark"
            multilined
          >
            <p class="truncate-2">
              {{ arrendamiento ? garantia.descripcion : tipo_garantia.nombre }}
            </p>
          </b-tooltip>
        </div>
      </div>
      <div class="column text-left flex justify-center flex-col">
        <p class="has-text-grey-light">Folio</p>
        <p>{{ garantia.id }}</p>
      </div>
      <div class="column text-left flex justify-center flex-col">
        <p class="has-text-grey-light">Valor</p>
        <p>{{ garantia.valor | currency }} {{ garantia.moneda && garantia.moneda.clave }}</p>
      </div>
      <div v-if="arrendamiento" class="column flex justify-center flex-col">
          <p class="has-text-grey-light">Tipo</p>
          <b-tooltip
            :label="tipo_garantia.nombre"
            type="is-dark"
            multilined
          >
            <p class="truncate-2">
              {{ tipo_garantia.nombre }}
            </p>
          </b-tooltip>
      </div>
      <div v-else class="column text-left flex justify-center flex-col">
        <p class="has-text-grey-light">Estatus</p>
        <p v-if="garantia.fecha_reclamo">
          Reclamada
        </p>
        <b-button
          v-else
          type="is-primary"
          size="is-small"
          outlined
          class="rounded-lg"
          @click="reclamar"
          expanded
          >Reclamar</b-button
        >
      </div>
      <div v-if="!arrendando" class="column text-left flex items-center justify-center">
        <b-button
          v-if="activoEsArrendable"
          class="text-lg rounded-lg h-8"
          type="is-arrendamiento"
          outlined
          @click="$emit('crearArrendamiento', garantia)"
        >
          Crear arrendamiento
        </b-button>
        <b-button
          v-else
          class="text-lg"
          :class="{ 
            'has-text-arrendamiento' : arrendamiento, 
            'has-text-info' : !arrendamiento 
          }"
          type="is-text"
          tag="a"
          @click="
            goTo(
              config.client +
                '/#/administracion/garantias/detalles/' +
                garantia.id
            )
          "
        >
          Ver detalles
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { goTo } from "@/vendors/helpers";
import config from "@/../config";
export default {
  name: "garantiaCard",
  props: {
    garantia: Object,
    view_type: String,
    arrendamiento: Boolean,
    arrendando: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      config: null
    };
  },
  methods: {
    //Envia al parent la garantía a reclamar
    reclamar() {
      this.$emit("reclamar", this.garantia);
    },
    goTo(url) {
      if(this.arrendamiento && this.garantia.disposiciones.length > 0) {
        return this.$router.push('/disposicion/' + this.garantia.disposiciones[0].id);
      }

      goTo(url);
    }
  },
  computed: {
    activoEsArrendable() {
      return this.arrendamiento && (!this.garantia.disposiciones || this.garantia.disposiciones && this.garantia.disposiciones.length == 0);
    },
    tipo_garantia() {
      return this.garantia.tipos_garantia ? this.garantia.tipos_garantia : this.garantia.tipo_garantia;
    }
  },
  created() {
    this.config = config;
  }
};
</script>

<template>
  <div>
    <b-table
      :data="amortizaciones"
      height="auto"
      sticky-header
      class=" has-border-has-text-whiteradius mt-4 tabla-amortizacion w-full"
    >
      <b-table-column field="numero_amortizacion" label="Número" v-slot="props">
        {{ esUnaSolaAmortizacion ? 1 : props.row.num_amortizacion }}
      </b-table-column>
      <b-table-column field="fecha" label="Fecha de vencimiento" v-slot="props">
        {{ props.row.fecha }}
      </b-table-column>
      <b-table-column field="capital" label="Capital" v-slot="props">
        {{ props.row.capital }}
      </b-table-column>
      <b-table-column field="estatus" label="Estatus" v-slot="props">
        <p
          :class="{
            'has-text-warning-always': props.row.pagada == 'Pendiente de pago',
            'has-text-success-always': props.row.pagada == 'Pagada'
          }"
        >
          {{ props.row.pagada }}
        </p>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
export default {
  name: "TablaAmortizaciones",
  props: {
    amortizaciones: Array,
    esUnaSolaAmortizacion: {
      type: Boolean,
      default: false,
    },
  }
  
};
</script>
